import React from 'react'
import { graphql } from 'gatsby'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import Hero from '../components/hero'
import Container from '../components/container'
import BlockContent from '../components/block-content'

export const query = graphql`
  query TermsPageQuery {
    page: sanityPage(_id: { regex: "/(drafts.|)c06f0153-f9a8-4be9-aab2-88ca1ea21755/" }) {
      title
      _rawBody
      metaTitle
      metaDescription
      metaKeywords
    }
  }
`

const TermsPage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const page = data.page

  if (!page) {
    throw new Error(
      'Missing "Terms of use" page data. Open the studio at http://localhost:3333 and add "Terms of use" page data and restart the development server.'
    )
  }

  return (
    <Layout palette="invert">
        <SEO title={page.metaTitle || page.title} description={page.metaDescription} keywords={page.metaKeywords} />

        <Hero palette="invert">
            {page.title}
        </Hero>
      
        <Container>
            <BlockContent blocks={page._rawBody} />
        </Container>
    </Layout>
  )
}
TermsPage.defaultProps = {
  data: {
    page: {
      title: 'No title'
    }
  }
}
export default TermsPage
